import React from 'react';
import Image from 'components/base/Image';

const BlockImage = ({ data }) => {
  return (
    <section className="block bb">
      <figure className="aspect-ratio aspect-ratio--4x3 aspect-ratio--16x9-lg">
        <Image
          image={data.image}
          className="aspect-ratio--object aspect-ratio--image"
          loading="eager"
        />
      </figure>
    </section>
  );
};

export default BlockImage;
