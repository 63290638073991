import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout';
import classnames from 'classnames';
import BlockImage from 'components/modules/BlockImage';
import BlockRichText from 'components/modules/BlockRichText';
import BlockTextText from 'components/modules/BlockTextText';
import BlockImageText from 'components/modules/BlockImageText';
import BlockLinksList from 'components/modules/BlockLinksList';
// import SEO from '../components/site/SEO';

const About = ({ data }) => {
  //get the last item in the nodes - this is in case they have a draft version
  //of a new event page
  const aboutPage = data.allContentfulAbout.nodes.slice(-1)[0];
  const { title, heroImage, textImageMixedBlocks } = aboutPage;

  return (
    <Layout pageTitle={title}>
      <article className="container container--thin container--boxed">
        {/* a block with a full width image */}
        <BlockImage data={heroImage} />
        {/* a block with text left and image right */}
        {textImageMixedBlocks.map((block, index) => {
          if (block.__typename === 'ContentfulBlockRichText') {
            return (
              <BlockRichText
                key={index}
                data={block}
                className={classnames({
                  bb: index < textImageMixedBlocks.length - 1
                })}
              />
            );
          } else if (block.__typename === 'ContentfulBlockTextText') {
            return (
              <BlockTextText
                key={index}
                data={block}
                className={classnames({
                  bb: index < textImageMixedBlocks.length - 1
                })}
              />
            );
          } else {
            return (
              <BlockImageText
                key={index}
                data={block}
                className={classnames({
                  bb: index < textImageMixedBlocks.length - 1
                })}
              />
            );
          }
        })}
        {/* Links list block */}
        <BlockLinksList isNavList={true} className="bt" />
      </article>
    </Layout>
  );
};

export default About;

export const query = graphql`
  query aboutPage {
    allContentfulAbout {
      nodes {
        title
        heroImage {
          image {
            description
            fluid(maxWidth: 1400) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
        textImageMixedBlocks {
          __typename
          ... on Node {
            ... on ContentfulBlockRichText {
              title
              description {
                json
              }
              twoColumn
            }
            ... on ContentfulBlockTextText {
              title
              textLeft {
                json
              }
              textRight {
                json
              }
            }
            ... on ContentfulBlockImageText {
              title
              image {
                description
                fluid(maxWidth: 600) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
              setTextToLeft
              text {
                json
              }
            }
          }
        }
      }
    }
  }
`;
